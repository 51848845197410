import React from "react"
import { Helmet } from "react-helmet"

import LogoWhiteImg from "../images/icons/logo_white.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Foot from "../components/foot"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const OurStory = () => (
  <Layout>
    <SEO title="Our Story" />
    <Helmet>
      <meta property="og:image" content={ LogoWhiteImg } />
    </Helmet>
    <Container className="mt-4">
      <Row> 
        <Col>
          <h3>Our Story</h3>
          <div style={{fontSize: "12pt"}}>
            <p className="mt-3" style={{textAlign: "justify"}}>
              We have two facilities in Winchester, CA with 12 beds including private and shared rooms. These facilities 
              were born out of love for my Mom &mdash; to provide her, and others like her,  with a safe, nurturing, and 
              comfortable environment as they age. The houses were designed, appointed and furnished with the needs of 
              our loved ones in mind, as they transition from independence, to requiring assistance, to total dependence 
              on others for care. Our caregivers are experienced, loving and caring, and will be your trusted extended 
              family. We accept both male and female residents at all levels of care including hospice and memory care. 
              We invite you to come see the homes and meet our staff. You will love our beautiful facilities.
            </p>
            <p className="text-end mb-0">
              <b><i>Jennifer Hahn, Facility Administrator</i></b>
            </p>
            <p className="text-end" style={{fontSize: "10pt"}}>
              Administrator Certificate No. 6045448740
            </p>
          </div>
        </Col>
      </Row>

      <div style={{marginTop: "215px"}}>
        <Foot />
      </div>
    </Container>
  </Layout>
)

export default OurStory